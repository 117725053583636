<template>
  <v-container fluid fill-height justify="center">
    <v-data-table
      :headers="headers[$vuetify.breakpoint.xsOnly]"
      :items="items"
      :items-per-page="10"
      item-key="code"
      sort-by="issue_date"
      mobile-breakpoint="10"
      :search="search"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
      @click:row="handleClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Clients</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn class="mx-2" fab dark small color="#1c2e5a" @click="newForm">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createClient from "../../utils/createParty.js";

export default {
  components: {},
  mounted() {
    if (this.$route.params.client) {
      this.client = this.$route.params.client;
      this.get_items();
    }
  },
  data() {
    return {
      client: createClient(),
      chosenPhoto: null,
      table: "clients",
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ header: 1 }, { header: 2 }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    newOrder(e) {
      this.$router.push({ name: "Inicio", params: e });
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        agent_code: this.$store.state.profile.code,
        filters: [
          { field: "client", operator: "=", value: this.client.code },
          //   { field: "store", operator: "=", value: this.company.code },
          //   { field: "date(issue_date)", operator: ">=", value: this.issueDateFrom },
          //   { field: "date(issue_date)", operator: "<=", value: this.issueDateTo },
        ],
      };

      console.log(qry);
      this.loading_status = true;
      webserver("get_mytask_events", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.items = data;
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/photos/" +
        account +
        "/" +
        this.table +
        "/" +
        src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },

    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
